<template>
  <div class="home">
    <div v-if="user.id">

    
    

    <div>
      <div class="row">
        <div class="col-sm-3">

          <div class="pb-4">
            Benvenuto<br> <h4>{{ user.name }}</h4>
          </div>

        </div>
        <div class="col-sm-9">
         
        </div>
      </div>

    
      
    </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import CmMixin from '../mixins/CmMixin'

export default {
  mixins: [ CmMixin ],
  name: 'Home',
  data: () => {
		return {
      search: '',
			customers: {},
			
		}
	},
  methods:{
  
  },
  computed:{
    
  }
  
}
</script>
