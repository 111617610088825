<template>
  <div class="">
    <CartModal/>

    <div class="sticky-top">
      <nav class="navbar navbar-expand-lg navbar-light bg-light ">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img src="./assets/images/cm-logo.png">
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link to="/" class="nav-link">Home</router-link>
              </li>
              <li class="nav-item"  v-if="!userIsLogged">
                <router-link to="/login" class="nav-link">Accedi</router-link>
              </li>
              <li class="nav-item" v-if="userHasRole('admin')">
                <router-link to="/users" class="nav-link">Utenti</router-link>
              </li>
              <li class="nav-item" v-if="userHasRole('admin')">
                <router-link to="/jobs" class="nav-link">Jobs</router-link>
              </li>

              <li class="nav-item dropdown" v-if="userHasRole('admin') || userHasRole('agent')">
                <a class="nav-link dropdown-toggle" href="#" id="clientiDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Clienti
                </a>
                <ul class="dropdown-menu" aria-labelledby="clientiDropdown">

                  <li>
                    <router-link to="/customers" class="nav-link">Elenco</router-link>
                  </li>
                  <li>
                    <router-link to="/visits" class="nav-link">Visite</router-link>
                  </li>

                </ul>
                
              </li>

              
              
              <li class="nav-item" v-if="userHasRole('admin') || userHasRole('agent')">
                <router-link to="/catalog" class="nav-link">Catalogo</router-link>
              </li>
              
              <li class="nav-item dropdown" v-if="userHasRole('admin') || userHasRole('agent')">
                <a class="nav-link dropdown-toggle" href="#" id="documentiDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Documenti
                </a>
                <ul class="dropdown-menu" aria-labelledby="documentiDropdown">

                  <li v-if="userHasRole('admin') || userHasRole('agent')">
                    <router-link to="/orders" class="dropdown-item">Ordini elaborati</router-link>
                  </li>

                  <li v-if="userHasRole('admin') || userHasRole('agent')">
                    <router-link to="/carts-archive/quotes" class="dropdown-item">Preventivi</router-link>
                  </li>

                  <li v-if="userHasRole('admin') || userHasRole('agent')">
                    <router-link to="/carts-archive/temporary_orders" class="dropdown-item">Ordini temporanei</router-link>
                  </li>
                  <!--
                  <li v-if="userHasRole('admin') || userHasRole('agent')">
                    <router-link to="/invoices" class="dropdown-item">Fatture</router-link>
                  </li>
                  -->

                  <li v-if="userHasRole('admin') || userHasRole('agent')">
                    <router-link to="/shipping-documents" class="dropdown-item">Documenti di trasporto</router-link>
                  </li>

                  <li v-if="userHasRole('admin') || userHasRole('agent')">
                    <router-link to="/ordered-products" class="dropdown-item">Prodotti ordinati</router-link>
                  </li>

                  
                </ul>
                
              </li>

              <li class="nav-item dropdown" v-if="userHasRole('admin')">
                <a class="nav-link dropdown-toggle" href="#" id="ordiniDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Garanzie
                </a>
                <ul class="dropdown-menu" aria-labelledby="garanzieDropdown">

                  <li >
                    <router-link to="/warranties" class="dropdown-item">Elenco garanzie</router-link>
                  </li>

                  <li >
                    <router-link to="/warranty-job/create" class="dropdown-item">Genera codici</router-link>
                  </li>

                  <li >
                    <router-link to="/warranty-job/list" class="dropdown-item">Elenco generazioni</router-link>
                  </li>

                  
                </ul>
                
              </li>
              <li class="nav-item dropdown" v-if="userHasRole('admin')">
                <a class="nav-link dropdown-toggle" href="#" id="configurazioneDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Configurazione
                </a>
                <ul class="dropdown-menu" aria-labelledby="configurazioneDropdown">

                  <li>
                    <router-link to="/cart-additions/shipping" class="dropdown-item">Spedizioni</router-link>
                  </li>


                  <li>
                    <router-link to="/cart-additions/promotion" class="dropdown-item">Promozioni</router-link>
                  </li>

                  <li>
                    <router-link to="/price_lists" class="dropdown-item">Listini</router-link>
                  </li>

                  <li>
                    <router-link to="/product_categories" class="dropdown-item">Categorie di prodotto</router-link>
                  </li>

                  <li>
                    <router-link to="/product_groups" class="dropdown-item">Gruppi di prodotto</router-link>
                  </li>

                  <li>
                    <router-link to="/payment_modes" class="dropdown-item">Modalità di pagamento</router-link>
                  </li>

                  <li>
                    <router-link to="/shops" class="dropdown-item">Negozi</router-link>
                  </li>
                  
                </ul>
                
              </li>
              <li class="nav-item">
                <a v-if="userIsLogged" class="nav-link" href="#" @click.prevent="logout">Esci</a>
              </li>
            </ul>
            
          </div>
          <div>
            {{ user.email }}
          </div>
        </div>
      </nav>

      <div class="bg-dark p-2">
        <div class="row" v-if="userIsLogged">
          <div class="col-6">
            <div v-if="current_profile" class="d-inline-block pe-3">
              <span class="text-light me-2">Agente</span>
              <span class="badge badge-pill bg-light text-dark" >{{ current_profile.external_id }}</span>
            </div>

            <div v-if="current_customer" class="d-inline-block pe-3">
              <span class="text-light me-2">Cliente</span>
              <span class="badge badge-pill bg-light text-dark" >
                {{ current_customer.external_id }} <span v-if="current_customer.price_list && current_customer.price_list.id"> / {{ current_customer.price_list.code }}</span>
              </span>
            </div>


            <div v-if="current_profile || current_customer" class="d-inline-block pe-3">
              <a href="#" @click.prevent="resetSession" class="text-light"><i class="bi bi-x-circle"></i></a>
            </div>
          </div>
          <div class="col-6 text-end">
            <CartBadge/>
          </div>
        </div>
        
        
      </div>
    </div>

    <div class="container-fluid pt-4">
      <router-view/>

      
    </div>

  </div>
</template>

<style lang="scss">
  @import "./styles/webapp.scss";
</style>



<script>

import CmMixin from './mixins/CmMixin'
import CartModal from './components/CartModal'
import CartBadge from './components/CartBadge'
import axios from 'axios'
import $ from 'jquery'

import _default, { mapState } from 'vuex';



export default{
  components:{
    CartModal, CartBadge,
  },
  mounted: function(){

    $('.navbar-nav .nav-link:not(.dropdown-toggle), .navbar-nav .dropdown-item').on('click', function(){ $('.navbar-collapse').removeClass('show') });

    var now = Math.floor(Date.now() / 1000);

    if( this.token_expiration && (this.token_expiration < now))
      this.logout();

    var self = this; 
    axios.get('/config.json')
      .then(
        (response) => {

          console.log( response );

          if( response.data.api_url != undefined )
            self.$store.commit('setApiUrl', response.data.api_url);

          if( response.data.request_context != undefined )
            self.$store.commit('setRequestContext', response.data.request_context);



        }
      ).catch( [(error) => {
        self.$store.commit('setApiUrl', '/connector');
      }]);

      
    
  },
  mixins: [ CmMixin ],
  methods:{
    ping: function(){
      this.api.get('ping').then( function( response ){
        console.log( response.data );
      });
      /*
      .catch(function (error) {
        if (error.response) {
          if( error.response.status == 401)
            self.logout();
        }
      });
      */
    },

    resetSession: function(){
      if( this.confirm('Procedere col reset della sessione?') ){
          this.$store.commit('setCurrentProfile', null);
          this.$store.commit('setCurrentCustomer', null);
          this.$store.commit('resetCart');
      }
      
    }
  },
  computed: {
    ...mapState([
			
			'token_expiration',
			
    ]),
  },
}
</script>

