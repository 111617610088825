<template>
    <div class="cart-badge" v-if="cart.count > 0">
        <router-link to="/cart" >
            <span class="badge badge-pill bg-primary text-light"><i class="bi-cart"></i>&nbsp;{{ cart.count }} | &euro; {{ formatPrice(cart.total) }}</span>
        </router-link>
    </div>
    
</template>

<script>
import { defineComponent } from 'vue'
import CmMixin from '../mixins/CmMixin'

export default defineComponent({
    
  name: 'CartBadge',
  mixins: [ CmMixin ],
  data: function(){
     
  },
  
})
</script>