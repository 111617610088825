import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  state: {
    apiUrl: null,
    request_context: null,
    token: null,
    token_expiration: null,

    user : {
      
      role: null,
      name: null,
      email: null,

      profiles: [],
    },
    configuration: {},

    cart: {
      id: null,
      name: null,

      profile_id: null,
      profile_external_id: null,

      customer_id: null,
      customer_external_id: null,
      customer_payment_mode_external_id: null,
     
      items: [],
      count: 0,
      total: 0,

      shipping_items: null,
      promotion_item: null,

      total_weight: 0,

      notes: '',

      shipping_address_is_new: null,
      shipping_address: null,
      shipping_address_new: null,
      
      discount_percentage: 0,
      discount_amount: 0,
      total_final: 0,
      
    },

    current_profile: null,
    current_customer: null,
    current_product: null,
  },
  mutations: {

    setApiUrl: function( state, api_url ){
      state.api_url = api_url;
    },

    setRequestContext: function( state, request_context ){
      state.request_context = request_context;
    },

    handleLoginResponse: function( state, data ){

      if( data.token )
        state.token = data.token;
      
      if( data.token )
        state.token = data.token;

      if( data.user )
        state.user = data.user;

      if( data.configuration )
        state.configuration = data.configuration;

      if( data.token_expiration )
        state.token_expiration = data.token_expiration;

    },

    resetUser: function( state ){
      state.token = null;
      state.user = {};
    },

    setCart: function( state, cart ){
      state.cart = cart;

    },

    resetCart: function( state ){
      state.cart = {
        id: null,
        name: null,

        profile_id: state.current_profile ? state.current_profile.id : null,
        profile_external_id: state.current_profile ? state.current_profile.external_id : null,

        customer_id: state.current_customer ? state.current_customer.id : null,
        customer_external_id: state.current_customer ? state.current_customer.external_id : null,
        customer_payment_mode_external_id: state.current_customer && state.current_customer.payment_mode ? state.current_customer.payment_mode.external_id : null,
     
        items: [],
        count: 0,
        total: 0,

        shipping_items: null,
        promotion_item: null,

        total_weight: 0,

        notes: '',
        
        shipping_address: null,
        shipping_address_new: null,

        discount_percentage: 0,
        discount_amount: 0,
        total_final: 0,
      }

    },


    resetAll: function( state ){
      state.token = null;
      state.user = {};
      state.current_profile = null;
      state.current_customer = null;
      state.current_product = null;
      state.configuration = {};

      this.commit('resetCart');
  
    },

    setCurrentProfile: function( state, profile ){
      state.current_profile = profile;
      state.current_customer = null;

      var cart = state.cart;

      state.cart = cart;
    },

    setCurrentCustomer: function( state, customer ){
      state.current_customer = customer;

      var cart = state.cart;

      state.cart = cart;
    },

    setCurrentProduct: function( state, product ){
      
      state.current_product = product;
    },
    
    addItemToCart: function( state, item ){

      if( item.product_id && item.quantity > 0 ){
        // per ora non considero un aggiornamento di riga perche potrebbe avere sconti diversi 
        // var found = state.cart.items.find(element => element.id == item.id);
        state.cart.items.push( item );
                 
      }
      this.commit('updateCart');

    },

    removeCartItem: function( state, cart_index ){
      state.cart.items.splice( cart_index, 1);
      this.commit('updateCart');
    },


    setCartShippingItems: function( state, items ){

      state.cart.shipping_items = items ;
      this.commit('updateCart', true);

    },

    setCartPromotionItem: function( state, item ){

      state.cart.promotion_item = item ;
      this.commit('updateCart', true);

    },

    setCartShippingAddress: function( state, payload ){

      state.cart.shipping_address_is_new = payload.shipping_address_is_new ;
      state.cart.shipping_address = payload.shipping_address ;
      state.cart.shipping_address_new = payload.shipping_address_new ;

    },

    setCartDiscount: function( state, discount ){

      state.cart.discount_percentage = discount ;
      
      this.commit('updateCart', true);

    },

    
    updateCart: function( state, is_cart_addition ){


      if(!is_cart_addition && (state.cart.shipping_items || state.cart.promotion_item) ) {
        alert('Modificando il carrello sarà necessario ricalcolare le spese di spedizione e scegliere nuovamente la promozione.');

        state.cart.shipping_items = null ;
        state.cart.promotion_item = null ;
      }


      state.cart.items = state.cart.items.map( function( item ){
        var t = item.price * item.quantity;
        if( item.discount_1 )
          t = t * ((100 - item.discount_1) / 100 );
        if( item.discount_2 )
          t = t * ((100 - item.discount_2) / 100 );
			  item.row_total = t;
        return item;
      });

      state.cart.count = state.cart.items.length;
      state.cart.total = state.cart.items.reduce((a, b) => +a + +b.row_total, 0);

      if( state.cart.shipping_items ){
        for( var i in state.cart.shipping_items){
          state.cart.total += state.cart.shipping_items[i].row_total;
        }
      }
        
      if( state.cart.discount_percentage ){

        var discount = parseFloat(state.cart.discount_percentage);

        state.cart.total_final = state.cart.total * ((100 - discount ) / 100);
        state.cart.discount_amount = state.cart.total - state.cart.total_final;
        
      }
      else{
        state.cart.total_final = state.cart.total;

      }


      state.cart.total_weight = parseFloat(state.cart.items.reduce((a, b) => +a + +(b.weight * b.quantity), 0).toFixed(2));
    },

  },
  
 
  plugins: [vuexLocal.plugin]
})
