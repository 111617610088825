<template>

    <div class="modal fade" tabindex="-1" id="cartModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Aggiungi un prodotto al carrello <span v-if="current_customer">di&nbsp;<ProfileBadge :customer="current_customer"/></span></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                
                <div class="row mb-5" v-if="current_product && cart_item">
                    <div class="col-sm-6">
                        <CatalogImage :code="current_product.external_id" :key="current_product.external_id" :imgclass="'img-product img-fluid'"/>
                    </div>
                    <div class="col-sm-6">

                        <h3 class="badge bg-dark badge-product mb-3">{{ cart_item.product_code }}</h3>

                        <div v-if="cart_item.description" class="mb-2"><strong>{{ cart_item.description }}</strong></div>
                        <hr> 

                        <table class="table small">
                            <tbody>
                                <tr v-if="current_product.packaging_quantity">
                                    <td>Quantità per confezione</td>
                                    <td class="text-end"><strong>{{ current_product.packaging_quantity }}</strong></td>
                                </tr>

                                <tr v-if="current_product.weight">
                                    <td>Peso unitario</td>
                                    <td class="text-end"><strong>{{ current_product.weight }} kg</strong></td>
                                </tr>

                                <tr>
                                    <td>Disponibilità</td>
                                    <td class="text-end"><strong>{{ current_product.quantity }}</strong> /{{ current_product.quantity_code }}</td>
                                </tr>

                                <tr>
                                    <td>Ultima Sincronizzazione</td>
                                    <td class="text-end"><strong>{{ formatDatetime(current_product.updated_at) }}</strong></td>
                                </tr>

                                <tr v-if="currentCommission">
                                    <td>Provvigione</td>
                                    <td class="text-end">
                                        <strong>{{ currentCommission.rate }}%</strong>&nbsp;
                                        <i class="bi bi-person" v-if="currentCommission.type == 'profile'"></i>
                                        <i class="bi bi-box" v-if="currentCommission.type == 'product'"></i>
                                    
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        <!--
                        <div class="small" v-if="current_product.packaging_quantity">Quantità per confezione: <strong>{{ current_product.packaging_quantity }}</strong></div>
                        <div class="small" v-if="current_product.weight">Peso unitario: <strong>{{ current_product.weight }}</strong> kg</div>
                        <div class="small" >Disponibilità: <strong>{{ current_product.quantity }}/{{ current_product.quantity_code }}</strong></div>
                        <div class="small" >Sincronizzazione: <strong>{{ formatDatetime(current_product.updated_at) }}</strong></div>
                        -->

                        <!--
                        <table class="table table-hover table-prices" v-if="cart_item.prices != undefined && cart_item.prices.length > 0">
                            <thead> 
                                <tr>
                                    <th class="w-50">Descrizione</th>
                                    <th class="w-25"></th>
                                    <th class="w-25 text-end">Prezzo</th>
                                </tr>  
                            </thead>
                            <tbody>
                                <tr v-for="price in cart_item.prices" v-bind:key="price.price_list_id" :class=" current_customer && (price.price_list_id == current_customer.price_list_id) ? 'table-success' : ''">
                                    <td><strong>{{ price.label }}</strong></td>
                                    <td class="text-center">
                                        <a href="#" v-if="!price.visible" @click.prevent="price.visible = true"><i class="bi bi-eye"></i></a>
                                        <a href="#" v-if="price.visible" @click.prevent="price.visible = false"><i class="bi bi-eye-slash"></i></a>
                                    </td>
                                    <td class="text-end">
                                        <span class="badge badge-pill bg-dark" v-if="price.visible">&euro; {{ formatPrice(price.price) }}</span>
                                        <span class="badge badge-pill bg-dark" v-else>---</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <small>In <span class="text-success">verde</span> è indicato il prezzo riservato al cliente selezionato</small>
                        -->


                    </div>
                    
                </div>

                
                <div  v-if="cart_item.price">
                    <div class="row gx-1 small">
                    
                        <div class="col-3 text-center">
                        Prezzo
                        </div>
                        <div class="col-3 text-center">
                        Quantità
                        </div>
                        <!--
                        <div class="col-1 text-center">
                            Sc.1 (%)
                        </div>
                        <div class="col-1 text-center">
                            Sc.2 (%)
                        </div>
                        -->
                        <div class="col-3 text-center">
                            Sconto
                        </div>
                        <div class="col-3 text-center">
                            Totale Parziale
                        </div>
                    </div>
                    <div class="row gx-1">
                        
                        <div class="col-3">
                            <input class="form-control text-end" type="text" disabled :value="formatPrice(cart_item.price)">
                        </div>
                        <div class="col-3">
                            <input class="form-control text-end" type="number" min="1" v-model="cart_item.quantity">
                        </div>
                        <!--
                        <div class="col-2">
                            <input class="form-control text-end" type="number" :disabled="current_product && current_product.disable_discounts" min="0" v-model="cart_item.discount_1">
                        </div>
                        <div class="col-2">
                            <input class="form-control text-end" type="number" :disabled="current_product && current_product.disable_discounts"  min="0" v-model="cart_item.discount_2">
                        </div>
                        -->
                        <!--
                        <div class="col-1">
                            <input class="form-control text-end" type="number" readonly min="0" v-model="cart_item.discount_1">
                        </div>
                        <div class="col-1">
                            <input class="form-control text-end" type="number" readonly  min="0" v-model="cart_item.discount_2">
                        </div>
                        -->
                        <div class="col-3">
                            
                            <select :disabled="current_product.disable_discounts"  class="form-select text-end" v-model="cart_item.discount_1" @change="setDiscount2()">
                                <option value="0">Nessuno sconto</option>
                                <option  v-for="i in 4" :key="i" :value="i">{{ i }} + {{ i }}</option>
                            </select>
                            
                        </div>
                        <div class="col-3">
                            <input class="form-control text-end" type="text" v-model="total" readonly>
                        </div>
                    </div>

                    

                    

                    <div class="form-group">
                        <label class="small">Note al prodotto</label>
                    </div>
                    <textarea class="form-control" rows="3" v-model="cart_item.notes"></textarea>

                </div>
                <div v-else class="alert alert-info">
                    Il cliente corrente  non è abilitato all'acquisto di questo prodotto
                </div>
                
            </div>
            <div class="modal-footer d-block">
                <div class="row">
                    <div class="col-sm-6">
                        <small class="text-danger" v-if="current_product && current_product.disable_discounts">Su questo prodotto non è possibile applicare sconti</small><br>
                        <small v-if="current_customer">Il prodotto verrà inserito nel carrello di <strong>{{ current_customer.company_name }}</strong></small>
                    </div>
                    <div class="col-sm-6 text-end">
                        <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">Chiudi</button>
                        <button v-show="cart_item.price" type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="addItemToCart( cart_item )">Aggiungi</button>
                    </div>
                </div>  
                
            </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import { defineComponent } from 'vue'
import CmMixin from '../mixins/CmMixin'

import CatalogImage from '../components/CatalogImage'
import ProfileBadge from '../components/ProfileBadge'

export default defineComponent({
    
  name: 'CartModal',
  mixins: [ CmMixin ],
  components: { CatalogImage, ProfileBadge },
  data: function(){
      return {
            cart_item: {
                product_id: null,
                product_code: null,
                description: null,
                price: null,
                quantity: 1,
                discount_1: 0,
                discount_2: 0,
                row_total: 0,
                notes: '',
                disable_discounts: false,
            },


            
      }
  },
  
  methods:{
     setDiscount2: function(){

        this.cart_item.discount_2 = this.cart_item.discount_1;

     }
  },
  computed: {
      total: function(){

        return this.calculateCartItemTotal( this.cart_item );
      },
      currentCommission: function(){

            

          if( this.current_product?.commission){
            return {
                rate: this.current_product.commission.rate,
                type: 'product'
            };
          }
          else if( this.current_profile?.commission){
            return {
                rate: this.current_profile?.commission?.rate,
                type: 'profile'
            };
          }
          else return null;

      }
  },
  watch: {
        current_product : function( new_val, old_val){
            var self = this;

            var prices = [];

            if( new_val && new_val.prices ){
                prices = new_val.prices.map( function( item ){
                    if( self.current_customer )
                        item.visible = ( item.price_list_id == self.current_customer.price_list_id) ;
                    else
                        item.visible = false;
                    return item;
                });
            

                this.cart_item = {
                    
                    product_id: new_val.id,
                    product_code: new_val.external_id,
                    description: new_val.description,
                    product_group: new_val.group ? new_val.group : null,
                    price: new_val.current_price,
                    weight: new_val.weight,
                    quantity: 1,
                    discount_1: 0,
                    discount_2: 0,
                    row_total: 0,
                    notes: '',

                    prices: prices,
                    disable_discounts: new_val.disable_discounts,
                }
            }
        },

        

        total: function(new_val, old_val){
            this.cart_item.row_total = new_val;
        }
  }
})
</script>